// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "r_rz d_gS d_cw d_dv";
export var quoteParagraphCenter = "r_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "r_gV d_gV d_cw d_dx";
export var quoteRowLeft = "r_rB d_bG";
export var quoteRowCenter = "r_rC d_bD";
export var quoteRowRight = "r_rD d_bH";
export var quoteWrapper = "r_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "r_gR d_gR";
export var quoteExceptionSmall = "r_rF t_rF";
export var quoteExceptionNormal = "r_rG t_rG";
export var quoteExceptionLarge = "r_rH t_rH";
export var quoteAuthorExceptionSmall = "r_rJ t_rJ";
export var quoteAuthorExceptionNormal = "r_rK t_rK";
export var quoteAuthorExceptionLarge = "r_rL t_rL";