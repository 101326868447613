// extracted by mini-css-extract-plugin
export var dark = "w_tl";
export var darkcookie = "w_tm";
export var tintedcookie = "w_tn";
export var regularcookie = "w_tp";
export var darkbase = "w_tq";
export var tintedbase = "w_tr";
export var regularbase = "w_ts";
export var darkraw = "w_tt";
export var tintedraw = "w_tv";
export var regularraw = "w_tw";
export var darkchick = "w_tx";
export var tintedchick = "w_ty";
export var regularchick = "w_tz";
export var darkherbarium = "w_tB";
export var tintedherbarium = "w_tC";
export var regularherbarium = "w_tD";
export var darkholiday = "w_tF";
export var tintedholiday = "w_tG";
export var regularholiday = "w_tH";
export var darkoffline = "w_tJ";
export var tintedoffline = "w_tK";
export var regularoffline = "w_tL";
export var darkorchid = "w_tM";
export var tintedorchid = "w_tN";
export var regularorchid = "w_tP";
export var darkpro = "w_tQ";
export var tintedpro = "w_tR";
export var regularpro = "w_tS";
export var darkrose = "w_tT";
export var tintedrose = "w_tV";
export var regularrose = "w_tW";
export var darktimes = "w_tX";
export var tintedtimes = "w_tY";
export var regulartimes = "w_tZ";
export var darkwagon = "w_t0";
export var tintedwagon = "w_t1";
export var regularwagon = "w_t2";