// extracted by mini-css-extract-plugin
export var alignLeft = "p_qj d_bG";
export var alignCenter = "p_bP d_bD";
export var alignRight = "p_qm d_bH";
export var textAlignLeft = "p_q4";
export var textAlignCenter = "p_q5";
export var textAlignRight = "p_q6";
export var hoursInnerWrapperAlt = "p_q7 d_fb d_P d_Z d_bz d_bD d_bP d_dj";
export var hoursInnerWrapperLeft = "p_q8 d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5 d_dj";
export var hoursText = "p_q9 d_dw";
export var titleMargin = "p_rb d_cw";
export var hoursInnerInnerWrapper = "p_rc d_cz";