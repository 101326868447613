// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "n_qM d_gv d_cs";
export var heroHeaderCenter = "n_gw d_gw d_cs d_dw";
export var heroHeaderRight = "n_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "n_qN d_gr d_cw";
export var heroParagraphCenter = "n_gs d_gs d_cw d_dw";
export var heroParagraphRight = "n_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "n_qP d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "n_qQ d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "n_qR d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "n_qS d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "n_qT d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "n_qV t_qV";
export var heroExceptionNormal = "n_qW t_qW";
export var heroExceptionLarge = "n_qX t_qX";
export var Title1Small = "n_qY t_qY t_rN t_rP";
export var Title1Normal = "n_qZ t_qZ t_rN t_rQ";
export var Title1Large = "n_q0 t_q0 t_rN t_rR";
export var BodySmall = "n_q1 t_q1 t_rN t_r5";
export var BodyNormal = "n_q2 t_q2 t_rN t_r6";
export var BodyLarge = "n_q3 t_q3 t_rN t_r7";