// extracted by mini-css-extract-plugin
export var carouselContainer = "B_vr d_w d_H d_bf d_Z";
export var carouselContainerCards = "B_vs B_vr d_w d_H d_bf d_Z";
export var carouselContainerSides = "B_vt d_w d_H d_Z";
export var prevCarouselItem = "B_vv d_w d_H d_0 d_k";
export var prevCarouselItemL = "B_vw B_vv d_w d_H d_0 d_k";
export var moveInFromLeft = "B_vx";
export var prevCarouselItemR = "B_vy B_vv d_w d_H d_0 d_k";
export var moveInFromRight = "B_vz";
export var selectedCarouselItem = "B_vB d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "B_vC B_vB d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "B_vD B_vB d_w d_H d_Z d_bf";
export var nextCarouselItem = "B_vF d_w d_H d_0 d_k";
export var nextCarouselItemL = "B_vG B_vF d_w d_H d_0 d_k";
export var nextCarouselItemR = "B_vH B_vF d_w d_H d_0 d_k";
export var arrowContainer = "B_vJ d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "B_vK B_vJ d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "B_vL B_vK B_vJ d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "B_vM B_vJ d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "B_vN";
export var nextArrowContainerHidden = "B_vP B_vM B_vJ d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "B_kG d_0";
export var prevArrow = "B_vQ B_kG d_0";
export var nextArrow = "B_vR B_kG d_0";
export var carouselIndicatorContainer = "B_vS d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "B_vT d_w d_bz d_bF";
export var carouselText = "B_vV d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "B_vW B_vV d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "B_vX d_b7";
export var carouselIndicator = "B_vY B_vX d_b7";
export var carouselIndicatorSelected = "B_vZ B_vX d_b7";
export var arrowsContainerTopRight = "B_v0 d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "B_v1 d_0 d_bl d_bC";
export var arrowsContainerSides = "B_v2 d_0 d_bl d_bC";
export var smallArrowsBase = "B_v3 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "B_v4 B_v3 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "B_v5 B_v4 B_v3 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "B_v6 B_v3 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "B_v7 B_v6 B_v3 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "B_p9";
export var iconSmall = "B_v8";
export var multipleWrapper = "B_v9 d_bC d_bF d_bf";
export var multipleImage = "B_wb d_bC";
export var sidesPrevContainer = "B_wc B_v4 B_v3 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "B_wd B_v4 B_v3 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";